import './StatCounter.style.scss';
import { ReactComponent as LoadingSpinner } from '../../assets/images/loading/spinner.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getHighestPaymentAmount, getTotalPayPackages, JobsSelector } from '../../redux/slices/jobs';
import { useEffect } from 'react';

const StatCounter = () => {
  const { fetchingTotalPP, fetchingHighestPay, totalPayPackages, highestPayAmount } = useSelector(JobsSelector);
  const dispatch = useDispatch();

  useEffect(async () => {
    await dispatch(getTotalPayPackages());
    await dispatch(getHighestPaymentAmount());
  }, []);

  if ((totalPayPackages === 0 && !fetchingTotalPP) && (highestPayAmount === 0 && !fetchingHighestPay)) {
    return null
  }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return <section className="stat-counter-section">
      <div className="align-center stats-counter container py-4">
        {totalPayPackages === 0 && !fetchingTotalPP ? null : <div className="stats-counter-box">
            <div className={'stats-counter-content'}>
              {fetchingTotalPP ? (
                <LoadingSpinner width={50} stroke={'#ffffff'} />
              ) : (
                numberWithCommas(totalPayPackages)
              )}
            </div>
            <div className="stats-counter-title">PAY PACKAGES AVAILABLE</div>
          </div>}
        {highestPayAmount === 0 && !fetchingHighestPay ? null : <div className="stats-counter-box">
            <div className={'stats-counter-content'}>
              {fetchingHighestPay ? <LoadingSpinner width={50} stroke={'#ffffff'} /> : <>
                  <sup className={'stats-counter-sign'}>$</sup> {numberWithCommas(+highestPayAmount.toFixed(0))}
                </>}
            </div>
            <div className="stats-counter-title">HIGHEST WEEKLY PAY</div>
          </div>}
      </div>
    </section>;
};

export default StatCounter;